/**
 * Returns the value for the given URL parameter
 */
export const getUrlParameter = (parameter: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get(parameter);
};

export const getSubdomain = (): string | undefined => {
  let domain, subdomain;
  if (window.location.href.includes("://")) {
    domain = window.location.href.split("://")[1];
    subdomain = domain.split(".")[0];
  }
  return subdomain;
};

/**
 * Convert camelCase to kebab-case
 */
export const camelToKebabCase = (str: string): string => {
  return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
};

/**
 * Convert snake_case to kebab-case
 */
export const snakeToKebab = (str: string): string => {
  return str.replace(/_/g, "-");
};

/**
 * Escape HTML special characters
 */
export const escapeHtml = (str: string): string => {
  return str
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
};

/**
 * Returns if the given RGB or HEX color is light or dark
 */
export const lightOrDark = (color: string): "light" | "dark" => {
  let r: number, g: number, b: number;

  // Check if the color is in RGB format
  if (color.match(/^rgb/)) {
    // Extract the RGB values
    const rgbMatch = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
    );
    if (rgbMatch) {
      r = parseInt(rgbMatch[1], 10);
      g = parseInt(rgbMatch[2], 10);
      b = parseInt(rgbMatch[3], 10);
    } else {
      throw new Error("Invalid RGB color format");
    }
  } else {
    // Convert HEX to RGB
    const hexColor = parseInt(color.slice(1), 16);
    r = (hexColor >> 16) & 255;
    g = (hexColor >> 8) & 255;
    b = hexColor & 255;
  }

  // HSP equation: http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Determine if the color is light or dark
  return hsp > 127.5 ? "light" : "dark";
};
