import type { FetchContext } from "ofetch";
import type { ConsolaInstance } from "consola";
import type { NuxtApp } from "#app";
import { useAssistantId } from "@/composables/useAssistantId";
import { Keys, usePreferences } from "@/composables/usePreferences";

const { assistantId } = useAssistantId();
const { getPreferences, setPreferences, removePreferences } = usePreferences();

export default defineAppConfig({
  sanctum: {
    tokenStorage: {
      get: async (app: NuxtApp) => {
        if (import.meta.server) {
          return undefined;
        }

        // @TODO: Figure out how we can make sure to get the assistant ID before anything else. For
        // now it seems it's this place, but it seems random.
        assistantId.value =
          app.$config.public.assistantId || useRoute().query.aid;

        // Get token from local storage
        const token = await getPreferences({
          key: Keys.token,
        });

        return token?.value ?? undefined;
      },

      set: async (app: NuxtApp, token?: string) => {
        if (import.meta.server) {
          return;
        }

        if (!token) {
          // Clear from local storage
          await removePreferences({
            key: Keys.token,
          });
          return;
        }

        // Save token from local storage
        await setPreferences({
          key: Keys.token,
          value: token,
        });
      },
    },
    interceptors: {
      onRequest: async (
        _app: NuxtApp,
        ctx: FetchContext,
        logger: ConsolaInstance,
      ) => {
        // Add the asssistant ID to the request headers
        if (assistantId.value) {
          ctx.options.headers.set("X-Assistant-Id", assistantId.value);
          logger.debug(`Added header X-Assistant-Id: (${assistantId.value})`);
        } else {
          // Redirect to static error page
          window.location.href = "https://wijsneus.app";
        }
        // Check if settings ID parameter is set to load draft settings
        const settingsId = <string>useRoute().query.sid;
        if (settingsId) {
          ctx.options.headers.set("X-Settings-Id", settingsId);
        }
      },
    },
  },
});
