import { useAssistantSettingsStore } from "@/stores/assistantSettingsStore";
import { snakeToKebab } from "@/utils";
import { FetchError } from "ofetch";

interface Theme {
  [name: string]: string;
}

export default defineNuxtPlugin(async () => {
  const sanctumFetch = useSanctumClient();

  const fetchAssistantSettingsData = async () => {
    const assistantSettingsStore = useAssistantSettingsStore();

    let response;

    try {
      response = await sanctumFetch("/assistant-settings", {
        query: {
          draft: 1,
        },
      });
    } catch (error: unknown) {
      if (error instanceof FetchError) {
        throw createError({
          statusCode: error.statusCode,
          statusMessage: error.data?.message ?? error.statusMessage,
          fatal: true,
        });
      } else {
        throw createError({
          statusCode: 500,
          statusMessage: "Unknown error occurred",
          fatal: true,
        });
      }
    }

    assistantSettingsStore.assistantSettings = response.data;

    applyAssistantTheme(response.data.theme);
  };

  const applyAssistantTheme = (theme: Theme) => {
    if (theme) {
      Object.keys(theme).forEach((key) => {
        const cssVariable = `--${snakeToKebab(key)}`;
        document.documentElement.style.setProperty(cssVariable, theme![key]);
      });
    }
  };

  await fetchAssistantSettingsData();
});
