import { useSuggestionsStore } from "@/stores/suggestionsStore";
import { usePreferences } from "@/composables/usePreferences";

export default defineNuxtPlugin(() => {
  const { isAuthenticated, user } = useSanctumAuth<IUser>();

  const { index } = useSuggestions();

  const suggestionsStore = useSuggestionsStore();

  const fetchSuggestions = async () => {
    const newSuggestions = await index();
    Object.assign(suggestionsStore.suggestions, newSuggestions);
  };

  const updateUserId = async () => {
    const { getPreferences, setPreferences } = usePreferences();

    const oldUserId = (
      await getPreferences({ key: "userId" })
    ).value?.toString();
    const newUserId = user.value?.ulid.toString();
    // Check if a new user has logged in
    if (newUserId !== oldUserId) {
      // Update user id in local storage
      if (newUserId) {
        await setPreferences({
          key: "userId",
          value: newUserId,
        });
      }
    }
  };

  onNuxtReady(async () => {
    // Fetch data when the user logs in
    watch(
      isAuthenticated,
      async (newValue) => {
        if (newValue) {
          updateUserId();
          fetchSuggestions();
        }
      },
      { immediate: true },
    );
  });
});
